.rowHome {
    width: 100%;
    display: flex;
}

._gauche {
    width: 50%;
    float: right;
}

._droite {
    width: 50%;
}

.cardRow {
    width: 90%;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 5px;
    box-shadow: 0px 1px 5px 1px rgb(0 0 0 / 30%);
    cursor: pointer;
    background-color: #ebebeb;
    color: #037baa;
}

.cardRow:hover {
    animation: anim1 0.5s linear 1
}

.cardRow>img {
    width: 75px;
    height: 75px;
    margin: 10px;
}

.cardRow>h3 {
    font-size: 14px;
}