.collap-conge{
    padding: 10px;
    border: 1px solid #4c2191;
    margin: 15px;
    border-radius: 5px;
    display: grid;
    grid-template-columns: 100%;
    position:relative;
    overflow: hidden;
}
.collap-conge>div:first-child{
    grid-column: 1/2;
    display: flex;
    align-items: center;
}
.collap-conge>div:first-child>div:first-child{
    width: 80px;
    height: 80px;
    margin: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #bac5dd;
}

.collap-conge>div:first-child>div>img{
    width: 60px;
    height: 60px;
    border-radius: 50%;
}
.collap-conge>div:first-child>div:last-child{
    background-color: #bac5dd;
    margin: 10px;
    width: calc(100% - 80px);
    display: block;
    height: 80px;
    display: flex;
    align-items: center;
    padding: 10px;
    font-size: 17px;
}

.collap-conge>div:nth-child(2){
    grid-column: 1/2;
    display: flex;
    align-items: center;
}
.collap-conge>div:nth-child(2)>div:first-child{
    width: 80px;
    height: 80px;
    margin: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #bac5dd;
}

.collap-conge>div:nth-child(2)>div>img{
    width: 50px;
    height: 50px;
}
.collap-conge>div:nth-child(2)>div:last-child{
    background-color: #bac5dd;
    margin: 10px;
    width: calc(100% - 80px);
    height: 80px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;
    font-size: 17px;
}

.collap-conge>div:nth-child(3){
    grid-column: 1/2;
  padding: 10px;
  margin: 10px;
  background-color: #bac5dd;

}
.collap-conge>div:last-child{
   position: absolute;
top: 20%;
right: 0px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: white;
z-index: 2;
box-shadow: -2px 0px 5px 1px rgba(0,0,0,0.43);
transition: 1.2s ease-in-out;
transform: translateX(110%);
}
.collap-conge>div:last-child img{
    width: 30px;
    height: 30px;
    margin: 10px;
    cursor: pointer;
}
.collap-conge>div:last-child span{
    margin: 10px;
}
.collap-conge:hover.collap-conge>div:last-child{
    transform: translateX(0px);
}


