.App{
  background-color: #fff;
  width: 100%;
  min-height: 100vh;
  position: relative;
  overflow: hidden;
  padding-top: 150px;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif !important;
}
.App::after{
content: "";
position: absolute;
width: 300%;
height: 2000px;
border-radius: 50%;
background-color: #037baa;
left: -250%;
top: -860px;
transform: translate(50%,-50%);
}
.App>img{
  position: absolute;
  top: 20px;
  left: 100px;
  z-index: 3;
  width: 190px;
  height: 60px;
}
.App>span{
  position: absolute;
  top: 70px;
  right: 70px;
  z-index: 3;
  color: white;
  font-size: 13px;
}
@media screen and (max-width:800px){
  .App>img{
  left: 60px;
  }
}
@media screen and (max-width:650px){
  .App>img{
  left: 15px;
  width: 150px;
  height: 50px;
  }
  .App>span{
   right: 40px;
   top: 80px;
  }
}