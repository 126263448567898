.login-page{
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 70vh;
}
.login-page>div{
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}
.login-page>div>div:first-child{
    text-align: center;
    width: 100%;
}
.login-page>div>div:first-child>h2{
    font-weight: 800;
    color: #037baa;
    font-size: 35px;
    margin-bottom: 20px;
}
.login-page>div>div:first-child>p{
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 20px;
}


.login-page form{
    width: 100%;
    display: flex;
    flex-direction: column;
}
.mp-form{
    position: relative;
}
.login-page form .form-control{
    border-radius: 35px;
    background-color: #ebeeee;
    padding-left: 52px;
    height: 45px;
}
.login-page form img{
    position: absolute;
    left: -4px;
    top: -4px;
    width: 50px;
    height: 50px;
}
.login-page form>button{
    width: 130px;
    margin: auto auto !important;
    padding: 12px;
    background-color: #0380b1 !important;
    color: white;
    border-radius: 10px;
    border: 1px solid transparent;
}
.login-page form>button:hover{
    color: #0380b1 !important;
    background-color: white !important;
border: 1px solid #0380b1;
}
@media screen and (max-width:900px){
    .login-page>div{
        width: 60%;
    }
    .login-page>div>div:first-child>h2{
        font-size: 30px;
    }
}
@media screen and (max-width:600px){
    .login-page>div{
        width: 90%;
    }
    .login-page>div>div:first-child>h2{
        font-size: 27px;
    }
}