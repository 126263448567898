.dash{
    padding: 10%;
    padding-top: 5%;
    overflow: hidden;
}

.welcome-dash{
width: 100%;
display: grid;
grid-template-areas: "image text";
grid-template-columns: 50% 50%;
margin-bottom: 20px;
}
.welcome-dash>div:first-child{
   width: 100%;
   display: flex;
   justify-content: center;
   align-items: center;
   grid-area: image;
}
.welcome-dash>div:first-child>img{
    width: 300px;
    height: 300px;
}
.welcome-dash>div:nth-child(2){
   width: 100%;
   display: flex;
   justify-content: center;
   align-items: center;
   grid-area: text;

}
.welcome-dash>div:nth-child(2)>div{
    width: 80%;
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 5px;
    background-color: #4671c6;
    color: #ffea92;
    padding-left: 20px;
    font-size: 20px;
 }
.welcome-dash>div:nth-child(2)>div>img{
    height: 50px;
    width: 50px;
    cursor: pointer;
}


.selc{
    width: 100%;
    overflow: hidden;
    position: relative;
    display: grid;
    grid-template-columns: 100%;
}
.selc-map{
  width: 100%;
  grid-column: 1/2;
  grid-row: 1/2;  
  transition: 1.5s ease-in-out;
}
.selc-map>div:first-child{
    width: 100%;
    background-color: #ebebeb;
    color:#4671c6;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    margin: 20px 0px;
    font-size: 25px;
    font-weight: 600;
}
.selc-map>div:nth-child(2){
    width: 100% ;
    padding: 10px;
    display: grid;
    grid-template-columns: 25% 25% 25% 25%; 
    place-items: center;
    transition: 1.5s ease-in-out;
    height: max-content;
}

.selc-map>div:nth-child(2)>div{
    width: 200px;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 5px;
    box-shadow: 0px 1px 5px 1px rgb(0 0 0 / 30%);
    cursor: pointer;
    background-color: #ebebeb;
    color: #037baa;
}
.selc-map>div:nth-child(2)>div:hover{
    animation: anim1 0.5s linear 1
}

@keyframes anim1{
    0%,100%{
        transform: translateY(0px);
       } 
         50%{
            transform: translateY(-10px);
        }
    
}

.selc-map>div:nth-child(2)>div>img{
    width: 75px;
    height: 75px;
    margin: 10px;
}
.selc-map>div:nth-child(2)>div>h3{
font-size: 14px;
}

.selc-slide{
    width: 100%;
    transition: 1.5s ease-in-out;
    transform: translateX(110%);
    grid-row-start: 1;
    grid-row-end: 2;
    grid-column: 1/2;
}
.selc.slide .selc-map{
    transform: translateX(-110%);
}
.selc.slide .selc-slide{
transform: translateX(0%);
}

.offre-alert{
    position: fixed;
    top: 80%;
    right: 10px;
    border-radius: 6px;
    background:  #37c95c;
    color: white;
    padding: 7px;
    font-size: 20px;
    transition: 0.5s;
}
.erreur-form{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid #E73528;
    border-radius: 0.25rem;
    padding: 5px;
}
.erreur-form>p{
    text-align: center;
    color: black;
    margin:0px;
}
.selection{
    width: 100%;
    display: flex;
    justify-content: space-around;
margin-top: 20px;

color: #4671c6;
}

.selection>div{
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    background-color: #ebebeb;
    width: 50%;
    margin: 10px;
    padding: 10px;
    padding-right: 0px;
    box-shadow: 0px 1px 5px 1px rgb(0 0 0 / 30%);
    position: relative;
}
.selection>div:hover{
   background: linear-gradient(to right,white,#ebebeb)
}

.selection>div>span{
      font-size: 20px;
    font-weight: 600;
}
.selection>div>img{
    width: 50px;
    height: 50px;
}

.selection+div{
    padding: 5%;
transform: translateX(-110%);
transition: 1.2s ease-in-out;
}
.selection.slide+div{
    transform: translateX(0%);
}
.add-offre{
    width: 100%;
    background-color: #ebebeb;
    color: #4671c6;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 55px;
    margin: 20px 0px;
    font-size: 25px;
    font-weight: 600;
}
.add-offre>h3{
    margin: 0px;
}

.dash .form-label{
    color: #4671c6;
}
.dash form .form-control{
    border: 1px solid #cccaca;
}
.dash form .form-check{
display: flex;
align-items: center;
}
@media screen and (max-width:1100px){
    .selc-map>div:nth-child(2){
        grid-template-columns:  50% 50%; 
    }
    .selc-map>div:nth-child(2)>div{
      margin: 20px;
    }
}

@media screen and (max-width:991px){
    .welcome-dash{
        grid-template-areas: "text" "image";
        grid-template-columns: 100%
        }
        .welcome-dash>div:first-child{
          margin : 20px 0px
         }
     
         .welcome-dash>div:nth-child(2){
             margin: 20px 0px;
         
         }
}
@media screen and (max-width:650px){
    .selection{
       flex-direction: column;
       align-items: center;
    }
    .selection>div{
        width: -webkit-fill-available;
    }
}
@media screen and (max-width:600px){
    .selc-map>div:nth-child(2){
        grid-template-columns:  100%; 
    }
}
@media screen and (max-width:430px){
    .dash{
        padding: 5%;
    }
    .selection+div{
        padding: 0%;
    }
    .welcome-dash>div:nth-child(2)>div{
        width: 90%;
     }
}