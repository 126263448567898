.Douter {
  position: absolute;
  width: 100%;
  height: 100%;
}

.outer .inner {
  display: flex;
}

.inner .fa {
  margin: auto;
  color: #4671c6;
  /* Center vertically and horizontally */
  position: fixed;
  top: 42%;
  left: 50%;
}