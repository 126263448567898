.add-user {
    width: 100%;
    background-color: #ebebeb;
    color: #4671c6;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 55px;
    margin: 20px 0px;
    font-size: 25px;
    font-weight: 600;
}

.add-user>h2 {
    margin: 0px;
}

.register-page {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 70vh;
    margin: 30px 0px;
}

.register-page>div {
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.register-page>div>div:first-child {
    text-align: center;
    width: 100%;
}

.register-page>div>div:first-child>h2 {
    font-weight: 800;
    color: #037baa;
    font-size: 35px;
    margin-bottom: 20px;
}

.register-page>div>div:first-child>p {
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 20px;
}


.register-page form {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.mp-form {
    position: relative;
}

.register-page form .form-control {
    border-radius: 35px;
    background-color: #ebeeee;
    height: 45px;
}

.register-page form img {
    position: absolute;
    left: -4px;
    top: -4px;
    width: 50px;
    height: 50px;
}

.register-page form>button {
    width: 130px;
    margin: auto auto !important;
    padding: 12px;
    background-color: #0380b1 !important;
    color: white;
    border-radius: 10px;
    border: 1px solid transparent;
}

.register-page form>button:hover {
    color: #0380b1 !important;
    background-color: white !important;
    border: 1px solid #0380b1;
}

@media screen and (max-width:900px) {
    .register-page>div {
        width: 60%;
    }

    .register-page>div>div:first-child>h2 {
        font-size: 30px;
    }
}

@media screen and (max-width:600px) {
    .register-page>div {
        width: 90%;
    }

    .register-page>div>div:first-child>h2 {
        font-size: 27px;
    }

    .add-user>h2 {
        font-size: 22px;
    }

    .add-offre>h3 {
        font-size: 22px;
    }
}

@media screen and (max-width:400px) {
    .register-page form #tel::placeholder {
        font-size: 14px;
    }
}