.offres-list{
    width: 100%;
    margin: 0px;
}
.offres-list>div{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px;
}
.offre-card{
    border-radius: 10px;
    box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.75);
    width: 90%;
    height: 300px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    padding: 10px;
    margin: 20px 0px;
    background-color: #ebebeb;
        color: #0380b1;
        transition: 0.4s;
        flex-direction: column;
  
}
.offre-card:hover{
    animation: anim1 0.5s linear 1
}

.offre-card>img{
    width: 50px;
    height: 50px;
    position: absolute;
    top: 10px;
    right: 10px;
}

.offre-card .content{
    line-height: 2;
top: 25px;
width: 100%;
}
.offre-card>div:nth-child(1){
    border-bottom: 2px solid #934ba2;
    width: 100%;
}
.offre-card>div:nth-child(1)>h2{
    padding-bottom: 0px;
    border-bottom: none;
    font-size: 25px;
    }
.offre-card>div:nth-child(1)>p{
   text-align: start;
    }

    .place-offre{
        position: absolute;
        right: 5%;
        bottom: 1%;
        color: #0380b1;
        transition: 0.3s;
        display: flex;
        align-items: center;
    }
    .place-offre>img{
        margin-right: 5px;
        width: 30px;
        height: 30px;
    }
    .manage_offer{
        position: absolute;
        left: 5%;
        bottom: 1%;
        transition: 0.3s;
        display: flex;
        align-items: center;
    }
    .manage_offer img{
        margin: 0px 5px;
        cursor: pointer;
        width: 30px;
        height: 30px;
    }
    .no-delete{
        background-color: white !important;
    }
    .yes-delete{
        background-color: #0380b1 !important;
    }
   
    .no-offre{
        color: #3d3d3d;
        font-size: 23px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 30px;
    }
    .no-offre i{
        margin-right: 10px;
        font-size: 21px;
    }
    .expiration{
        position: absolute;
        bottom: 2%;
        right: 40%;
        color : #E73528
    }
    .expiration-offre{
        width: 100%;
        margin: 30px 10% 0px 5%;
        color:#303030;
        font-size: 20px;
    }
    .expiration-offre i{
        margin-right: 7px;
    }
    .modifier{
        width: 100%;
        background-color: #ebebeb;
        color: #4671c6;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 60px;
        margin: 20px 0px;
        font-size: 25px;
        font-weight: 600;
    }
    .modifier>h3{
        margin: 0px;
    }

    @media screen and (max-width:400px){
        .offre-card>div:nth-child(1)>h2{
            font-size: 22px;
            }
    }