.title {
    padding: 5px 0 0;
    font-size: 32px;
    line-height: 1.2;
    color: #333;
    font-weight: 400;
    font-family: open sans, sans-serif;
    text-align: center;
}

.qst {
    font-size: 22px;
    line-height: 1.2;
    padding-bottom: 18px;
    color: #333;
    font-weight: 400;
    font-family: open sans, sans-serif;
}
.time {
    font-size: 18px;
    line-height: 1.2;
    padding-bottom: 18px;
    color: #333;
    font-weight: 400;
    font-family: open sans, sans-serif;
    margin-left: 6px;
}

.qstAlign {
    display: flex;
    align-items: baseline;
}

.message.answer {
    background-color: #3aaf3e47;
    border-color: #17732fa6;
    color: #000;
    margin: 5px 0;
    width: 97%;
}

.message.wrong {
    background-color: #c91d0a47;
    border-color: #d44e39a6;
    color: #000;
    margin: 5px 0;
    width: 97%;
}

.message {
    position: relative;
    display: block;
    background-color: #FAFAFA;
    padding: 20px;
    margin: 20px 0;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    color: #2f3239;
    border: 1px solid;
}